@import 'core';

/* layout */
.inner {
  max-width: 1440px;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  margin: 0 auto;

  &.main {
    padding-left: 0;
    padding-right: 0;
  }
}
.notosans {
  font-family: 'Noto Sans KR', sans-serif;
}
.only_m {
  display: none;
}
#content {
/*  
  margin: 64px 0 0;
  padding: 40px 0 100px;
*/
  margin: 0;
  padding: 0 0 100px;
  min-height: calc(100vh - 122px);
}
.layout_5_5 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.layout_5_5 > * {
  width: 50%;
}
.layout_2_8 {
  display: flex;
}
.layout_2_8 > *:nth-child(1) {
  width: 20%;
}
.layout_2_8 > *:nth-child(2) {
  width: 80%;
}
.contnet_top_border {
  border-top: 38px solid #211b1b;
}

/* HEADER */
#header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: #fff;
}
#header .menu_wrap {
  position: relative;
  z-index: 10;
  padding: 40px 0;
  text-align: center;
}
#header .logo {
  width: 127px;
  font-weight: 900;
  font-size: 30px;
}
#header .logo a {
  display: block;
}
#header .gnb {
  display: flex;
  //position: absolute;
  //top: 50%; /*padding:0 6%;*/
  //transform: translateY(-50%);
}
#header .gnb.right {
  //right: 0;
}
#header .gnb > li {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 32px;
}
#header .gnb > li:first-child {
  margin-left: 0;
}
#header .gnb > li > a {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #272727;
  font-weight: 500;
}

#header .gnb_w .gnb > li > a {
  font-weight: bold;
  line-height: 1;
  color: #000000;
  padding: 12px 0;
}

#header .gnb .qr {
  position: relative;
}
#header .gnb .qr a {
  font-weight: 800;
  font-size: 12px;
}
#header .gnb .qr .icon {
  width: 17px;
  margin-left: 2px;
}
#header .q_box {
  display: none;
  position: absolute;
  right: 60px;
  top: 90px;
  width: 200px;
  margin-top: -10px;
  padding: 19px 15px;
  border: 1px solid #444;
  border-radius: 13px;
  background: #fff;
}
#header .q_box .author {
  margin: 0 0 10px;
  background: url(/assets/icons/path-3-right@2x.png) no-repeat 100% 50% / 3px;
}
#header .q_box .author .profile_img {
  width: 22px;
  min-width: 22px;
  height: 22px;
  position: relative;
}
#header .q_box .author .profile_img img {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
#header .q_box .author strong {
  display: block;
  overflow: hidden;
  width: calc(100% - 40px);
  font-size: 10px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#header .q_box .img_qr {
  width: 67px;
}
#header .q_box .img_copy {
  width: 8px;
  margin-left: 3px;
}
#header .q_box .info_text {
  font-size: 10px;
  padding: 14px 0 5px;
  border-bottom: 1px solid #b2b2b2;
  text-align: left;
}
#header .q_box .copy p {
  font-size: 10px;
  padding: 14px 0 2px;
}
#header .q_box .copy a {
  display: block;
  font-size: 10px;
  font-weight: 400;
  margin: 2px 0 0;
}
#header .q_box .link {
  display: block;
  height: 25px;
  line-height: 23px;
  border: 1px solid #c9c9c9;
  border-radius: 6px;
  margin: 10px 0 0;
  font-size: 11px;
  color: #272727;
  font-weight: 400;
}
#header .gnb .mypage img {
  width: 17px;
}
#header .gnb .ico_arrow {
  width: 6px;
  margin-left: 5px;
  transform: rotate(180deg);
}
#header .only_pc .gnb .snb {
  display: none;
}
#header .only_pc .gnb > li:hover .snb {
  display: block;
}
#header .only_pc .gnb .mypage .snb {
  left: -85px;
}
#header .only_pc .gnb > li:hover .ico_arrow {
  transform: rotate(0);
}
#header .snb {
  position: absolute;
  top: 100%;
  left: 0;
  width: 185px;
  //margin-top: -10px;
  border-radius: 5px;
  border: 1px solid #f6f6f6;
  background: #fff;
  box-shadow: 0 3px 10px 0 rgba(69, 69, 69, 0.16);
  text-align: left;
}
#header .snb > li > span,
#header .snb > li > a {
  display: block;
  padding: 9px 12px;
  font-size: 12px;
  font-weight: 500;
}
#header .snb > li:last-child > a {
  border-bottom: 0;
}

/* HEADER - DEPATH 3(pc) */
#header .gnb_w .snb .depth3 {
  padding: 5px 12px;
  background-color: #f6f6f6;
}
#header .gnb_w .snb .depth3 > li > a {
  display: block;
  padding: 5px 0;
  font-size: 11px;
  border-bottom: 1px solid #e4e4e4;
}
#header .gnb_w .snb .depth3 > li:last-child > a {
  border-bottom: 0;
}

/* HEADER - mobile */
#header .ham span:first-child {
  margin: 0;
}
#header .qr_m img {
  width: 20px;
}
#header .mobile_layout {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
}
#hamburger {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: white;
  border-radius: 50%;
  border: none;
  outline: none;
}
#hamburger span {
  position: absolute;
  width: 20px;
  height: 1px;
  top: 50%;
  left: 50%;
  background: #262626;
  border-radius: 2px;
  overflow: hidden;
  transition: all 0.3s linear;
}
#hamburger span::before {
  content: '';
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  transition: all 0.3s linear;
}
#hamburger span:nth-child(1) {
  animation: span-first-off 0.5s ease-in-out;
  animation-fill-mode: forwards;
}
#hamburger span:nth-child(2) {
  animation: span-second-off 0.5s ease-in-out;
  animation-fill-mode: forwards;
}
#hamburger span:nth-child(3) {
  animation: span-third-off 0.5s ease-in-out;
  animation-fill-mode: forwards;
}
#hamburger.on:hover span::before {
  width: 100%;
  transition: all 0.3s linear;
}
#hamburger.on span:nth-child(1) {
  animation: span-first-on 0.5s ease-in-out;
  animation-fill-mode: forwards;
}
#hamburger.on span:nth-child(2) {
  animation: span-second-on 0.5s ease-in-out;
  animation-fill-mode: forwards;
}
#hamburger.on span:nth-child(3) {
  animation: span-third-on 0.5s ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes span-first-on {
  0% {
    transform: translate(-50%, -700%);
  }
  30% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
@keyframes span-first-off {
  0% {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  30% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -700%);
  }
}
@keyframes span-second-on {
  0% {
    transform: translate(-50%, -50%);
  }
  25% {
    background: gray;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-150%, -50%) scale(0);
  }
}
@keyframes span-second-off {
  0% {
    transform: translate(-150%, -50%) scale(0);
  }
  25% {
    background: gray;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
@keyframes span-third-on {
  0% {
    transform: translate(-50%, 600%);
  }
  30% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
@keyframes span-third-off {
  0% {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  30% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, 600%);
  }
}

/* 프로필 */
.author {
  display: flex;
  align-items: center;
}
.author strong {
  font-size: 12px;
  padding-right: 10px;
}
.author .profile_img {
  position: relative;
  overflow: hidden;
  width: 32px;
  min-width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  border: 3px solid #000;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);

  img {
    @include translate-center;
    min-width: 100%;
    min-height: 100%;
  }
}

/* 버튼 */
.btn_ty01 {
  display: inline-block;
  padding: 0 8px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #000;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.5px;
}
.btn_ty01.black {
  background-color: #000;
  border-color: #000;
  color: #fff;
}
.btn_ty01.darkgray {
  background-color: #4b4b4b;
  border-color: #4b4b4b;
  color: #fff;
}
.btn_ty01.lightgray {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
  color: #fff;
}
.btn_ty02 {
  display: inline-block;
  padding: 9px 30px;
  font-size: 16px;
  background-color: #4a4a4a;
  color: #fff;
  border-radius: 17px;
  line-height: 1;
  font-weight: 400;
}
.btn_ty02.lightgray {
  background-color: #d8d8d8;
}
.btn_ty03 {
  display: block;
  width: 180px;
  height: 40px;
  margin: 0 auto;
  line-height: 40px;
  border-radius: 26px;
  font-size: 12px;
  background: rgb(79, 83, 223);
  background: linear-gradient(180deg, rgba(79, 83, 223, 1) 0%, rgba(109, 111, 255, 1) 100%);
  text-align: center;
  font-weight: 600;
  color: #fff;
}
.btn_more {
  display: block;
  width: 123px;
  height: 35px;
  margin: 5px auto 0;
  text-align: center;
  border: 1px solid #c1c1c1;
  border-radius: 17px;
  font-size: 14px;
  color: #5c5c55;
  letter-spacing: -1px;
  line-height: 35px;
}
.btn_more img {
  width: 6px;
  margin-left: 14px;
}

/* DISPLAY */
.d_flex {
  display: flex;
}

/* LAYER POPUP */
.layer_popup {
}
.layer_popup .popup {
  @include gt-xs() {
    min-width: 450px;
  }
  position: relative;
  width: 100%;
  max-width: 450px;
  height: auto;
  padding: 55px 45px;
  background-color: #fff;
}
.layer_popup .popup .btn_close {
  position: absolute;
  right: 40px;
  top: 37px;
  font-size: 45px;
  margin-top: -20px;
  font-weight: 300;
}
.layer_popup .popup .popup_header {
  position: relative;
  font-size: 15px;
  color: #929292;
  font-weight: 400;
  margin: 0 0 8px;
}
.layer_popup .popup .popup_content {
  font-size: 17px;
  color: #161616;
  font-weight: 600;
  letter-spacing: -1px;
}
.layer_popup .popup .popup_footer {
  margin: 30px 0 0;
  text-align: right;
}
.layer_popup .popup .popup_footer a {
  margin-left: 10px;
}

/* 라디오버튼 */
.radio_wrap .radio {
  display: inline-block;
  margin-left: 10px;
}
.radio_wrap .radio:first-child {
  margin-left: 0;
}
.radio_wrap .radio label {
  font-size: 14px;
  vertical-align: middle;
}
.radio_wrap .radio input {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 5px 0 0;
  vertical-align: middle;
}

/* 폰트 */
.IBM {
  font-family: 'IBM Plex Sans Thai Looped', sans-serif;
}

/* (공통) 페이지 타이틀 */
.mb_45 {
  margin-bottom: 45px;
}
.page_title {
  margin: 0 0 40px;
  font-size: 27px;
  font-weight: 300;
  letter-spacing: -1px;
}
.page_title .sub_title {
  margin: 25px 0 0;
}
.page_title .sub_title p {
  margin: 4px 0 0;
  font-size: 11px;
  letter-spacing: 0;
  font-weight: 300;
}
.page_title .sub_title p:first-child {
  margin: 0;
}
.page_title.type02 {
  display: inline-block;
  padding: 0 2px 3px;
  border-bottom: 3px solid #000;
  font-size: 24px;
}
.c_orange {
  color: #f68422;
}
.c_blue {
  color: #001fbb;
}
.shadow_box {
  box-shadow: 5px 4px 11px 0 rgba(0, 0, 0, 0.12);
  border-radius: 30px;
}

/* check button */
.check_ty01 {
  font-size: 0;
}
.check_ty01 input[type='checkbox'],
.check_ty01 input[type='radio'] {
  overflow: hidden;
  width: 0;
  height: 0;
  border: 0;
  position: absolute;
  left: -99999px;
}
.check_ty01 label {
  font-size: 10px;
}
.check_ty01 label:before {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  vertical-align: top;
  content: '';
  border: 1px solid #979797;
  vertical-align: middle;
}
.check_ty01 input[type='checkbox']:checked + label:before,
.check_ty01 input[type='radio']:checked + label:before {
  background: url(/assets/icons/check_on@2x.png) no-repeat 50% 50% / 7px;
}
.check_ty02 {
  font-size: 0;
}
.check_ty02 input[type='checkbox'],
.check_ty02 input[type='radio'] {
  overflow: hidden;
  width: 0;
  height: 0;
  border: 0;
  position: absolute;
  left: -99999px;
}
.check_ty02 label {
  position: relative;
  font-size: 16px;
  font-weight: 600;
}
.check_ty02 label:before {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  border: 1px solid #d8d8d8;
  margin-right: 8px;
  vertical-align: top;
  content: '';
  vertical-align: middle;
}
.check_ty02 label.checked:before {
  background-image: url(/assets/icons/icon.jpg);
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: 8px;
}

/* 정렬 */
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

/* 결제방식 */
.payment .user_name {
  display: none;
  margin: 5px 0;

  &.show {
    display: block;
  }
}
.payment .user_name input {
  padding: 5px 10px;
  border: 1px solid #aaa;
}
.radio_wrap input[type='checkbox'],
.radio_wrap input[type='radio'] {
  position: absolute;
  left: -999999px;
  width: 0;
  height: 0;
}
.radio_wrap label {
  cursor: pointer;
}
.radio_wrap label:before {
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-right: 10px;
  border: 4px solid #f5f5f5;
  border-radius: 50%;
  vertical-align: middle;
  content: '';
  background-color: #ddd;
  box-sizing: border-box;
}
.radio_wrap input[type='checkbox']:checked + label:before,
.radio_wrap input[type='radio']:checked + label:before {
  border-color: #f68422;
  background-color: #fff;
}

/* TAB */
.tab_wrap .tab_panel .panel {
  display: none;
}
.tab_wrap .tab_panel .panel.active {
  display: block;
}
.tab_wrap_toggle .tab_panel .panel {
  display: none;
}
.tab_wrap_toggle .tab_panel .panel.active {
  display: block;
}
.tab_ty01 {
  display: flex;
  align-items: center;
}
.tab_ty01 > * {
  position: relative;
  margin-left: 30px;
  font-size: 11px;
  padding: 8px 5px;
  cursor: pointer;
}
.tab_ty01 > *:first-child {
  margin-left: 0;
}
.tab_ty01 > *.active:before {
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #222;
  content: '';
  transform: translateX(-50%);
}

/* 카드 */


.item_list > li {

/*  
  width: calc(33.3% - 14px);
  margin-left: 21px;
  margin-bottom: 42px;
  border-radius: 20px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.03);
*/
  overflow: hidden;
  box-shadow: 0px 1px 3px 1px rgba(0,0,0, 0.1);
  border-radius: 13px;
  transition: box-shadow 300ms ease-in-out;

  &:hover {
    box-shadow: 0 3px 26px 0 rgba(0, 0, 0, 0.1);
  }
}
.item_list > li:nth-child(3n + 1) {
  margin-left: 0;
}
.item_list .info {
  padding: 22px;
}
.item_list .info .content {
}
.item_list .txt {
  width: calc(100% - 100px);
  font-size: 10px;
  line-height: 13px;
  display: -webkit-box;
  overflow: hidden;
  height: 4.8em;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 1.2em;
  white-space: normal;
}
.item_list .info .content .price {
  font-weight: 900;
  text-align: right;
  font-size: 18px;
  color: #272727;
  letter-spacing: -0.25px;
}
.item_list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  font-family: 'IBM Plex Sans Thai Looped', sans-serif;
}
.item_list .thumb {
  overflow: hidden;
  position: relative;
  padding: 0 0 92%;
  background-color: #f0f0f0;
}
.item_list .thumb img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: auto;
}
.item_list .title {
  display: flex;
  padding: 0 0 8px;
  align-items: center;
  justify-content: space-between;
}
.item_list .title .date {
  font-size: 10px;
}

.item_list.type02 .info {
  padding: 10px 12px;
  background-color: #f1f1f1;
}
.item_list.type02 .info .content {
  justify-content: space-between;
}
.item_list.type02 .info .content .price {
  font-size: 20px;
}
.item_list.type02 .info .date {
  font-size: 10px;
  font-weight: 400;
}
.item_list.type02 .txt {
  font-size: 10px;
  color: #707070;
  width: 100%;
}
.item_list.type02 .author strong {
  font-size: 14px;
}

/* 환율 */
.charge_info {
  display: flex;
  padding: 0 0 45px;
  align-items: center;
  border-bottom: 2px solid #2e2e2e;
}
.charge_info .shadow_box {
  width: 35%;
  padding: 35px 0;
  text-align: center;
}
.charge_info .shadow_box strong {
  font-size: 27px;
}
.charge_info .shadow_box p {
  margin: 5px 0 0;
  font-size: 12px;
  letter-spacing: -0.5px;
}
.charge_info .shadow_box p span {
  font-weight: 600;
}

/* 테이블 */
.table_ty01 {
  width: 100%;
  table-layout: fixed;
}
.table_ty01 thead th {
  padding: 8px 0;
  font-size: 12px;
  color: #999;
  letter-spacing: -0.5px;
}
.table_ty01 thead th:nth-child(1) {
  width: 8%;
}
.table_ty01 thead th:nth-child(2) {
  width: auto;
}
.table_ty01 thead th:nth-child(3) {
  width: 20%;
}
.table_ty01 tbody tr.strong a,
.table_ty01 tbody tr.strong td {
  color: #f68422;
}
.table_ty01 tbody td {
  padding: 8px 0;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: -0.5px;
}

/* 라인 */
.line1 {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 페이지네이션 */
.pagination {
  text-align: right;
  padding: 30px 0 0;
}
.pagination a {
  display: inline-block;
  margin: 0 5px;
  text-align: center;
  font-size: 12px;
  color: #999999;
  letter-spacing: -0.5px;
  vertical-align: middle;
}
.pagination a.on {
  font-weight: bold;
  color: #4a4a4a;
}
.pagination .arrow img {
  width: 4px;
}
//.pagination .arrow.prev {
//  transform: rotate(180deg);
//}
.pagination02 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}
.pagination02 a {
  display: inline-flex;
  width: 22px;
  height: 22px;
  margin: 0 2px;
  border-radius: 50%;
  font-size: 12px;
  color: #000;
  align-items: center;
  justify-content: center;
}
.pagination02 a.on {
  background-color: #000;
  color: #fff;
}
.pagination02 .arrow.prev {
  margin-right: 20px;
}
.pagination02 .arrow.next {
  margin-left: 20px;
}
.pagination02 .arrow img {
  width: 8px;
}

/* 프로필 */
.author.type02 .profile_img {
  border: 0;
  margin-right: 13px;
}
.author.type02 .info strong {
  display: block;
  margin: 0 0 4px;
}
.author.type02 .info p {
  font-size: 10px;
  color: #808080;
}
.author.type02 .info .p2 {
  margin: 2px 0 0;
}

/* 아코디언 */
.accordion_wrap .accordion_list > li {
  border-bottom: 1px solid #2e2e2e;
}
.accordion_wrap .accordion_list .accordion_title {
  padding: 14px 0;
  font-size: 11px;
  cursor: pointer;
}
.accordion_wrap .accordion_list .accordion_title img {
  width: 8px;
  margin-left: 8px;
  transition: transform 0.3s;
}
.accordion_wrap .accordion_list .accordion_title.show img {
  transform: rotate(180deg);
}
.accordion_wrap .accordion_list .accordion_panel {
  padding: 0 0 20px;
}
.accordion_wrap .accordion_list .accordion_panel dl {
  display: flex;
  align-items: center;
  padding: 8px 0;
}
.accordion_wrap .accordion_list .accordion_panel dl dt {
  width: 70%;
  font-size: 11px;
}
.accordion_wrap .accordion_list .accordion_panel dl dd {
  @include ellipsis;
  font-size: 11px;
}

/* 404 */
.notfound {
  padding: 50px;
}
.notfound h1 {
  font-size: 65px;
  margin: 0 0 30px;
  color: #474747;
}
.notfound p {
  font-size: 20px;
  color: #474747;
}

/* 충전 수량 */
.quantity .input_area {
  position: relative;
}
.quantity .input_area span {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.quantity .input_area input {
  width: 100%;
  font-size: 16px;
  color: #2e2e2e;
  font-weight: 800;
  letter-spacing: -0.5px;
  border: 1px solid #979797;
  height: 35px;
  line-height: 33px;
  padding: 0 10px;
  padding-right: 45px;
}

/* 로딩바 */
#loadingBar {
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
}
#loadingBar .item {
  border-radius: 4px;
  transition: opacity 0.4s ease;
  width: 18vmin;
  height: 18vmin;
}
#loadingBar .loader {
  display: block;
  position: relative;
  width: 50%;
  width: 18vmin;
  height: 18vmin;
}
#loadingBar .loader::before,
#loadingBar .loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
}
#loadingBar .loader.l6::before {
  width: 3vmin;
  height: 3vmin;
  background-color: white;
  top: calc(50% - 3vmin);
  left: calc(50% - 3vmin);
  animation: loader-6 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}
@keyframes loader-6 {
  0%,
  100% {
    transform: none;
  }
  25% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(100%) translateY(100%);
  }
  75% {
    transform: translateY(100%);
  }
}

/* 검색바 */
.search_wrap02 input[type='text'] {
  width: 200px;
  padding: 8px 0 8px 30px;
  background: url(/assets/icons/ic_search@2x.png) no-repeat 0 50%;
  background-size: 20px;
  border: 0;
  outline: none;
  border-bottom: 1px solid #000;
}
.search_wrap02.search-page input[type='text'] {
  width: 100%;
  padding-top: 5px;
  background: url(/assets/icons/ic_search@2x.png) no-repeat 0 0;
  background-size: 20px;
  font-size: 12px;
  line-height: 1;
}

.search_wrap02.artist-search input[type='text'] {
  width: 320px;

  @include xs() {
    width: 260px;
    border-radius: 31px;
    background-color: #f4f4f4;
    border-bottom: none;
    padding-left: 50px;
    padding-right: 20px;
    background-position-x: 20px;
    margin-bottom: 20px;

    &::placeholder {
      color: #707070;
    }
  }
}
.search_wrap02 input[type='text']::placeholder {
  color: #d8d8d8;
}

/* select */
.custom-select {
  position: relative;
  margin-left: auto;
  font-size: 11px;
  letter-spacing: -0.8px;
  width: 210px;

  @include lt-lg {
    width: 150px;
  }
}
.custom-select select {
  display: none;
}
.select-items {
  position: absolute;
  padding: 6px 0;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 0 3px 10px 0 rgba(69, 69, 69, 0.16);
  border-radius: 5px;
}
.select-items div,
.select-selected {
  padding: 0 24px 0 16px;
  cursor: pointer;
  user-select: none;
}

nik-item-list-page .select-selected {
  padding: 4px 24px 0 16px;
}

.select-selected {
  font-weight: 800;
  font-size: 12px;
  text-align: right;
  background: url(/assets/icons/ic_setting@2x.png) no-repeat 100% 50%;
  background-size: 20px;
}
.select-hide {
  display: none;
}
.select-items div:hover,
.same-as-selected {
  background-color: #f1f1f1;
}

@media screen and (max-width: 1300px) {
  /*Tablet*/
  .inner {
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media screen and (max-width: 1024px) {
  /*Tablet*/
  #header .gnb {
    padding: 0 3%;
  }
  #header .gnb > li {
    margin-left: 20px;
  }

  /* 아코디언 */
  .accordion_wrap .accordion_list .accordion_panel dl dt {
    width: 50%;
  }
}
@media screen and (max-width: 1024px) {
  /*Tablet*/
  /* layout */
  .only_m {
    display: block;
  }
  .only_pc {
    display: none;
  }
  #content {
    margin: 62px 0 0;
    padding: 30px 0 70px;
  }
  .inner {
    padding-left: 5%;
    padding-right: 5%;
  }
  .contnet_top_border {
    border-top-width: 21px;
  }

  /* LAYER POPUP */
  .layer_popup .popup {
    min-width: 260px;
    padding: 80px 40px 50px;
  }
  .layer_popup .popup .btn_close {
  }
  .layer_popup .popup .popup_header {
    margin: 0 0 15px;
  }
  .layer_popup .popup .popup_footer {
    text-align: center;
    font-size: 0;
  }
  .layer_popup .popup .popup_footer a {
    font-size: 16px;
    width: calc(50% - 6px);
    margin-left: 12px;
    padding: 10px 0 8px;
  }
  .layer_popup .popup .popup_footer a:first-child {
    margin-left: 0;
  }

  /* 버튼 */
  .btn_ty01 {
    font-size: 12px;
  }

  /* 카드 */

  
/*
  .item_list > li {
    width: calc(33.3% - 14px);

      .nft-card2{
        width: 100% !important;
      }
  }
  .item_list > li:nth-child(5n) {
    margin-right: 15px;
  }
  .item_list > li:nth-child(3n) {
    margin-right: 0px;
  }
*/  
/*
  .item_list > li {
    width: calc(50% - 12px);
    margin-bottom: 30px;
  }
  .item_list > li,
  .item_list > li:nth-child(3n + 1) {
    margin-left: 24px;
  }
  .item_list > li:nth-child(2n + 1) {
    margin-left: 0;
  }
*/

  /* 환율 */
  .charge_info {
    padding: 0 0 20px;
    flex-direction: column;
    border-width: 1px;
  }
  .charge_info .shadow_box {
    width: 300px;
    margin: 0 0 25px;
    padding: 30px 0;
  }
  .charge_info .shadow_box strong {
    font-size: 22px;
  }

  /* 충전수량 */
  .quantity .input_area input {
    font-size: 13px;
  }
  .quantity .input_area span {
    font-size: 12px;
  }
}
@media screen and (max-width: 620px) {
  /*Mobile*/

  /* HEADER */
  #header .gnb {
    padding: 0;
  }

  /* 버튼 */
  .btn_ty02 {
    font-size: 14px;
    padding: 8px 25px 7px;
  }

  /* LAYER POPUP */
  #layerPopup .popup {
    max-width: 80%;
    padding: 50px 30px 40px;
  }
  #layerPopup .popup .btn_close {
    padding: 8px;
    margin-right: -8px;
    line-height: 1;
  }
  #layerPopup .popup .popup_header {
    font-size: 13px;
  }
  #layerPopup .popup .popup_content {
    font-size: 15px;
  }
  #layerPopup .popup .popup_footer a {
    margin-left: 5px;
  }

  /* 카드 */
/*  
  .item_list > li {
    width: 100%;
    margin-bottom: 24px;
  }
  .item_list > li,
  .item_list > li:nth-child(3n + 1) {
    margin-left: 0;
  }
  .item_list .info .content .txt {
    width: 70%;
  }
  .item_list .info .content .price {
    width: 100%;
  }
*/  
}
