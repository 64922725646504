@import 'core';

/* main - 키비쥬얼 */
.main .section01 {
  margin: 22px 0 0;
  border-bottom: 2px solid #2e2e2e;
}
.main .section01 .txt_area {
  position: relative;
  z-index: 2;
  width: 50%;
}
.main .section01 .txt_area h5 {
  padding: 0 0 0 17%;
  position: absolute;
  bottom: 0;
}
.main .section01 .txt_area h5 p {
  line-height: 1;
  font-family: 'IBM Plex Sans Thai Looped', sans-serif;
  letter-spacing: -0.5px;
}
.main .section01 .txt_area h5 p:nth-child(1) {
  font-size: 34px;
  font-weight: 500;
}
.main .section01 .txt_area h5 p:nth-child(2) {
  margin: -5px 0 0;
  padding: 0 0 0 45px;
  font-size: 64px;
}
.main .section01 .txt_area .small_txt {
  position: absolute;
  right: 150px;
  bottom: -50px;
  width: 136px;
  height: 180px;
  font-size: 10px;
  line-height: 13px;
  font-family: 'IBM Plex Sans Thai Looped', sans-serif;
}
.main .section01 .img_area {
  position: relative;
  width: 50%;
  height: 430px;
}
.main .section01 .img_area img {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  width: auto;
  max-width: 170%;
  height: 80%;
  max-height: 120%;
}
.main .section01 .vertical_txt {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 14px 0 0;
  writing-mode: vertical-rl;
  font-size: 14px;
}
.main .section01 .vertical_txt:after {
  position: absolute;
  left: -7px;
  bottom: 35px;
  width: 2px;
  height: 89px;
  content: '';
  background-color: #ffffff;
}
.main .section01 .swiper-slide a {
  display: flex;
}
.main .section01 .swiper-button-prev {
  left: 0;
  bottom: 40px;
  top: auto;
  width: 54px;
  height: 54px;
  background: url(/assets/icons/slide_btn.png);
}
.main .section01 .swiper-button-prev.swiper-button-disabled,
.main .section01 .swiper-button-next.swiper-button-disabled {
  pointer-events: initial;
}

/* main - 핫한작품 */
.main .section02 {
  padding: 75px 0 70px;
}
.main .section02 h3 {
  padding: 0 0 40px;
  font-size: 36px;
  font-weight: 300;
  letter-spacing: -3px;
}

/* 상품선택 */
.asset_product .page_title {
  font-weight: 400;
  font-family: 'IBM Plex Sans Thai Looped', sans-serif;
}
.asset_product .description dt {
  font-size: 10px;
  color: #474747;
}
.asset_product .description dt img {
  width: 21px;
  margin-right: 2px;
  vertical-align: middle;
}
.asset_product .description dd {
  margin: 10px 0 0;
  font-size: 11px;
  color: #272727;
  letter-spacing: -1px;
}
.asset_product .description dd span {
  color: #f68422;
}
.asset_product .choice {
  margin: 30px 0 75px;
}
.asset_product .all {
  width: 60%;
}
.asset_product .all .item.select:before {
  background-color: rgba(0, 0, 0, 0.68);
}
.asset_product .item_detail {
  display: flex;
  width: 40%;
  padding-left: 25px;
  flex-direction: column;
}
.asset_product .item_detail .item_title p {
  margin: 2px 0 0;
  font-size: 27px;
  letter-spacing: -2px;
  font-family: 'IBM Plex Sans Thai Looped', sans-serif;
}
.asset_product .item_detail .item_title p:first-child {
  margin: 0;
}
.asset_product .item_detail .item_input {
  display: flex;
  margin: 20px 0 0;
  justify-content: space-between;
  align-items: center;
}
.asset_product .item_detail .item_input input {
  width: calc(50% - 7.5px);
  height: 30px;
  line-height: 28px;
  margin-left: 15px;
  padding: 0 10px;
}
.asset_product .item_detail .item_input input:first-child {
  margin-left: 0;
}
.asset_product .item_img {
  margin-top: auto;
}
.asset_product .wrapper {
  display: inline-block;
  width: 40%;
  padding: 0 0 0 25px;
  text-align: right;
}
.asset_product .btns_wrap {
  text-align: right;
  padding: 50px 0 0;
  font-size: 0;
}
.asset_product .btns a {
  width: calc(50% - 7px);
}
.asset_product .btns a:nth-of-type(1) {
  margin-right: 14px;
}
.asset_product .col05 {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}
.asset_product .col05:before {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 1px;
  content: '';
  background-color: #fff;
}
.asset_product .col05 > * {
  position: relative;
  width: 20%;
}
.asset_product .col05 > *:before {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  content: '';
  background-color: transparent;
}
.asset_product .col05 > *:nth-child(5n + 1):before {
  border-left: 1px solid transparent;
}
.asset_product .col05 > *:nth-child(5n + 1):before {
  border-left: 1px solid transparent;
}
.asset_product .item {
  position: relative;
}
.asset_product .item .text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  font-size: 56px;
  font-weight: bold;
  color: #fff;
  -webkit-text-stroke: 1px #8d8d8d;
}
.asset_product .all .text {
  -webkit-text-stroke: initial;
  font-size: 17px;
}
.asset_product .radio_wrap {
  margin: 0 0 8px;
  text-align: left;
}
.asset_product .payment {
  text-align: left;
  margin: 0 0 30px;
}
.asset_product .payment input[type='text'] {
  height: 30px;
  line-height: 30px;
  border: 1px solid #222;
  border-radius: 0;
  padding: 0 10px;
}
.layer_popup.gallery {
  display: block;
}
.layer_popup.gallery .popup {
  max-width: 700px;
  padding: 0;
  background-color: transparent;
}
.layer_popup.gallery .popup_header .author {
  color: #fff;
}
.layer_popup.gallery .popup_header .author p {
  font-size: 12px;
}
.layer_popup.gallery .popup_content img {
  display: block;
  width: 90%;
  margin: 0 auto;
}
.layer_popup.gallery .popup .btn_close {
  line-height: 1;
  top: 0;
  margin-top: 0;
  color: #fff;
  font-size: 60px;
}
.layer_popup.gallery .popup .popup_header {
  margin: 0 0 40px;
}

/* 충전페이지 */
.charge .inner {
  padding: 0 5%;
}
.charge .charge_info .quantity {
  display: flex;
  width: 65%;
  padding: 0 50px;
}
.charge .charge_info .quantity .cell:first-child {
  width: 70%;
}
.charge .charge_info .quantity .cell:nth-child(2) {
  width: 30%;
  padding-left: 18px;
}
.charge .charge_info .quantity .cell input {
  font-weight: 600;
}
.charge .charge_info .quantity .cell p {
  padding: 5px 0 0 10px;
  font-size: 13px;
  color: #7e7e7e;
}
.charge .bottom {
  display: flex;
  padding: 40px 0 25px;
  justify-content: space-between;
  align-items: flex-end;
}
.charge .bottom .info_txt {
  font-size: 12px;
  color: #2e2e2e;
  font-weight: bold;
  margin: 28px 0 0;
}
.charge .bottom .total {
  display: block;
  color: #f68422;
  font-size: 16px;
  letter-spacing: -0.2px;
}
.charge .user_info .user_name {
  width: 40%;
}
.charge .btn_wrap {
  text-align: right;
}
.charge .btn_wrap .btn_ty01 {
  margin-top: 15px;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
}
.charge .btn_wrap .terms {
  margin-top: 8px;
}
.charge .btn_wrap .terms a {
  display: inline-block;
  width: 95px;
  height: 24px;
  font-size: 11px;
  color: #272727;
  border: 1px solid #ffbd85;
  line-height: 24px;
  text-align: center;
}
.charge .radio_wrap {
  margin-top: 15px;
}

/* 이용약관 */
.terms .shadow_box {
  padding: 38px 30px;
}
.terms .shadow_box .text_content {
  overflow-y: scroll;
  height: 500px;
}
.terms .shadow_box .text_content strong {
  display: block;
  font-size: 17px;
  color: #5b5b5b;
  letter-spacing: -1.5px;
}
.terms .shadow_box .text_content p {
  margin-top: 8px;
  font-size: 16px;
  color: #5b5b5b;
  letter-spacing: -1.5px;
  line-height: 1.7;
}

/* 로그인 */
.login {
  max-width: 260px;
  width: 100%;
  margin: 100px auto;
}
.login .btns {
  padding: 20px 0 0;
  text-align: center;
}
.login .btns a {
  font-size: 12px;
  margin-left: 12px;
}
.login .btns a:first-child {
  margin-left: 0;
}
.login .login_form {
  width: 100%;
  margin: 0 auto;
}
.login .login_form .info_text {
  margin: 0 0 7px;
  font-size: 12px;
  color: #f68422;
  text-align: center;
}
.login .login_form input {
  width: 100%;
  height: 34px;
  margin: 12px 0 0;
  padding: 0 20px;
  line-height: 34px;
  border-radius: 20px;
  border: 1px solid #979797;
  font-size: 12px;
}
.login .login_form input::placeholder {
  color: #ababab;
}
.login .login_form button[type='submit'] {
  width: 100%;
  margin: 19px 0 0;
  height: 40px;
  line-height: 40px;
  padding: 0;
  border-radius: 30px;
  font-size: 14px;
}

/* 회원가입 */
.join {
  max-width: 350px;
  width: 82%;
  margin: 50px auto;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 400;
}
.join .join_form .info_text {
  margin: 0 0 50px;
  font-size: 12px;
  color: #4a4a4a;
}
.join .join_form .row {
  display: flex;
  margin-top: 25px;
  align-items: center;
}
.join .join_form .row:first-child {
  margin-top: 0;
}
.join .join_form .row label {
  display: inline-block;
  position: relative;
  width: 100px;
  font-size: 13px;
  padding-left: 15px;
}
.join .join_form .row label:before {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  color: #f68422;
  font-size: 18px;
}
.join .join_form .row label.required:before {
  content: '*';
}
.join .join_form .row input {
  border: 0;
  border-bottom: 1px solid #4e4e4e;
  flex: 1;
}
.join .check_ty01 label {
  font-size: 11px;
}
.join .bottom {
  display: flex;
  margin: 80px 0 0;
  align-items: center;
  justify-content: space-between;
}
.join .bottom .agree_wrap > * {
  margin: 7px 0 0;
}
.join .bottom .agree_wrap > *:first-child {
  margin: 0;
}
.join .bottom button[type='submit'] {
  font-size: 13px;
  line-height: 36px;
  padding: 0;
  width: 132px;
  border-radius: 20px;
}

/* 장바구니 */
.cart {
  font-family: 'Noto Sans KR', sans-serif;
}
.cart .cart_table {
  width: 100%;
}
.cart .cart_table thead {
  border-bottom: 1px solid #2e2e2e;
}
.cart .cart_table thead th {
  padding: 12px 0;
  font-size: 12px;
  font-weight: 300;
}
.cart .cart_table thead th:nth-child(1) {
  width: 4%;
}
.cart .cart_table thead th:nth-child(2) {
  width: 70%;
}
.cart .cart_table thead th:nth-child(3) {
  width: 15%;
}
.cart .cart_table thead th:nth-child(4) {
  width: auto;
}
.cart .cart_table {
  border-bottom: 1px solid #2e2e2e;
}
.cart .cart_table tbody tr {
  border-top: 1px solid #cacaca;
}
.cart .cart_table tbody tr:first-child {
  border-top: 0;
}
.cart .cart_table tbody td {
  padding: 20px 0;
}
.cart .cart_table tbody .info {
  display: flex;
  align-items: center;
}
.cart .cart_table tbody .thumb {
  width: 95px;
  min-width: 95px;
  margin-right: 30px;
}
.cart .cart_table tbody .del button {
  font-size: 25px;
}
.cart .cart_table tbody .p_wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  flex: 1;
}
.cart .cart_table tbody .p_wrap .p1 {
  margin: 0 0 20px;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  word-break: keep-all;
}
.cart .cart_table tbody .p_wrap .p2 {
  font-size: 25px;
  font-weight: bold;
  width: 70%;
}
.cart .cart_table tbody .p_wrap .p3 {
  width: 30%;
  font-size: 12px;
  word-break: break-all;
}
.cart .cart_table .check_ty01 label:before {
  margin: 0;
}
.cart .table_btns {
  display: flex;
  margin: 20px 0 0;
  align-items: center;
}
.cart .table_btns a {
  margin-left: 5px;
  font-size: 11px;
  color: #474747;
  border-color: #474747;
  height: 24px;
  line-height: 22px;
  padding: 0 8px;
}
.cart .table_btns a:first-child {
  margin-left: 0;
}
.cart .table_btns .check_ty01 {
  width: 4%;
}
.cart .total {
  padding: 70px 0 50px;
  text-align: right;
  font-size: 13px;
  font-weight: 900;
}
.cart .total span {
  margin-left: 30px;
}
.cart .btns {
  margin: 15px 0 0;
}
.cart .btns a {
  margin-left: 8px;
  line-height: 32px;
  height: 34px;
  font-size: 13px;
}
.cart .btns a:first-child {
  margin-left: 0;
}
.cart .payment .user_name {
  margin: 14px 0 30px;
}

/* 창작작품 */
.work .top_author {
  margin: 30px 0 40px;
}
.work .top_author .link {
  display: inline-block;
  margin-left: 10px;
  padding: 5px 35px 5px 2px;
  font-size: 10px;
  border-bottom: 1px solid #000;
  background: url(/assets/icons/copy@2x.png) no-repeat 100% 50% / 8px;
}
.work .top_author .sns_list {
  display: flex;
  align-items: center;
  margin-left: 25px;
}
.work .top_author .sns_list > li {
  width: 10px;
  margin-left: 12px;
}
.work .top_author .sns_list > li:first-child {
  margin-left: 0;
}
.work .link_wrap {
  display: flex;
  align-items: center;
}
.work .panel {
  padding: 30px 0 0;
}
.work .tab_wrap .list > li {
  margin: 20px 0 0;
}
.work .tab_wrap .list > li:first-child {
  margin: 0;
}
.work .charge_info {
  border-bottom: 0;
}
.work .charge_info .shadow_box {
  width: 400px;
}
.empty {
  padding: 100px 0;
  text-align: center;
}
.empty h4 {
  margin: 0 0 50px;
  font-size: 20px;
  font-weight: 300;
}

/* 공지사항 */
.notice .search_wrap {
  margin: 0 0 35px;
}
.notice .search_wrap form {
  display: inline-block;
  position: relative;
  width: 380px;
}
.notice .search_wrap input {
  padding: 15px 40px 15px 15px;
  border: 3px solid #c9c9c9;
  width: 100%;
}
.notice .search_wrap input::placeholder {
  color: #4a4a4a;
}
.notice .search_wrap button[type='submit'] {
  position: absolute;
  right: 0;
  top: 0;
  width: 55px;
  height: 100%;
  padding: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.notice .search_wrap button[type='submit'] img {
  width: 25px;
}
.notice .list_wrap {
  padding-right: 50px;
}
.notice .text {
  position: relative;
  padding: 0 0 0 50px;
  border-left: 1px solid #c9c9c9;
  font-size: 17px;
  line-height: 1.5;
}
.notice .text:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #c9c9c9;
  transform: translateX(-50%);
  content: '';
  background-color: #fff;
}

/* (공통) 상세 - 상품상세, 에디션 */
.item_detail .inner {
  display: flex;
  padding: 0;
  align-items: flex-start;
}
.item_detail .inner .item_thumb {
  width: 450px;
}
.item_detail .inner .info_wrap {
  flex: 1;
}
.item_detail .inner .info_wrap .title h5 {
  color: #2e2e2e;
  font-size: 18px;
  line-height: 1.7;
}
.item_detail .inner .info_wrap .description .text {
  font-size: 12px;
  margin: 18px 0 0;
  line-height: 1.6;
  white-space: pre-wrap;
}
.item_detail .inner .info_wrap .description .price_wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 20px 0 0;
  padding: 0 0 15px;
}

.item_detail .inner .info_wrap .description .price_wrap .price p {
  font-size: 11px;
}
.item_detail .inner .info_wrap .description .price_wrap .price strong {
  font-size: 25px;
}
.item_detail .inner .info_wrap .description .price_wrap .price span {
  font-size: 11px;
  font-weight: 400;
  margin-left: 5px;
}

.item_detail .inner .info_wrap .btns {
  font-size: 0;
}
.item_detail .inner .info_wrap .btns a {
  width: calc(50% - 3px);
  height: 34px;
  line-height: 32px;
  font-size: 13px;
  font-weight: 400;
}
.item_detail .inner .info_wrap .btns a:first-child {
  margin-right: 6px;
}
.item_detail .inner .info_wrap .btns a:nth-child(3) {
  width: 100%;
  margin-top: 10px;
}
.item_detail .inner .author_list > li {
  margin: 10px 0 0;
}
.item_detail .inner .author_list > li:first-child {
  margin: 0;
}
.item_detail .inner .accordion_wrap {
  margin: 35px 0 0;
}
.item_detail .inner .quantity {
  margin: 10px 0;
}
.item_detail .quantity .input_area input::placeholder {
  color: #aaa;
  font-weight: 400;
  font-size: 14px;
}
.item_detail .inner .info_wrap.show_input .input_wrap {
  display: block;
}
.item_detail .inner .info_wrap.show_input .btns .btn_proposal {
  display: none;
}

/* 상품상세 */
.prd_detail .inner {
  padding: 0 5%;
  flex-direction: row-reverse;
}
.prd_detail .inner .info_wrap {
  padding-right: 48px;
}
.prd_detail .inner .info_wrap .title h5 {
  margin: 18px 0 0;
}
.prd_detail .inner .info_wrap .description .price_wrap .interested span {
  font-size: 11px;
  margin-left: 5px;
}
.prd_detail .inner .info_wrap .description .price_wrap .interested span:first-child {
  margin-left: 0;
}
.prd_detail .inner .info_wrap .description .price_wrap .interested span img {
  vertical-align: middle;
  margin-right: 2px;
}
.prd_detail .inner .info_wrap .description .price_wrap .interested span.like img {
  width: 11px;
}
.prd_detail .inner .info_wrap .description .price_wrap .interested span.view img {
  width: 14px;
}
.prd_detail .inner .info_wrap .etc {
  padding: 8px 0 38px;
}
.prd_detail .inner .info_wrap .etc a {
  display: inline-block;
  line-height: 1;
}
.prd_detail .inner .info_wrap .etc a.share {
  margin-right: 8px;
}
.prd_detail .inner .info_wrap .etc a.share img {
  width: 12px;
}
.prd_detail .inner .info_wrap .etc a.scale img {
  width: 14px;
}
.prd_detail .inner .info_wrap .description .price_wrap {
  border-bottom: 1px solid #2e2e2e;
}

/* 에디션 */
.edition .inner {
  display: block;
}
.edition .flex {
  display: flex;
  padding: 0;
  align-items: flex-start;
}
.edition .inner .info_wrap {
  padding-left: 40px;
}
.edition .inner .item_thumb {
  overflow: hidden;
  border-radius: 20px;
}
.edition .description .etc_list {
  display: flex;
  margin: 30px 0 0;
  align-items: center;
}
.edition .description .etc_list dl {
  display: flex;
  align-items: center;
  margin-right: auto;
}
.edition .description .etc_list dt {
  margin-right: 10px;
  font-size: 12px;
}
.edition .description .etc_list dd {
  font-size: 12px;
  font-weight: normal;
}
.edition .description .etc_list span {
  margin-left: 20px;
  font-size: 12px;
  white-space: nowrap;
}
.edition .description .etc_list span img {
  width: 16px;
  margin-right: 4px;
}
.edition .info_panel {
  margin-top: 20px;
  padding: 5px 0;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}
.edition .info_panel dl {
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: space-between;
}
.edition .info_panel dl dt {
  font-size: 12px;
  white-space: nowrap;
}
.edition .info_panel dl dd {
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
}
.edition .inner .info_wrap .description .price_wrap strong img {
  width: 36px;
}
.edition .inner .info_wrap .description .price_wrap .price p {
  font-size: 12px;
  font-weight: 600;
}
.edition .inner .info_wrap .description .price_wrap .price p span {
  font-weight: 400;
}
.edition .inner .info_wrap .description .price_wrap .price strong {
  display: block;
  margin-top: 10px;
  font-size: 30px;
}
.edition .inner .info_wrap .btns a {
  border-radius: 5px;
  height: 40px;
  line-height: 36px;
  font-size: 16px;
}
.edition .accordion_wrap .accordion_list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.edition .accordion_wrap .accordion_list > li {
  width: 450px;
  border-bottom: 0;

  @include lt-sm {
    width: 100%;
  }
}
.edition .accordion_wrap .accordion_list > li:nth-child(2n) {
  width: calc(100% - 450px);
  padding-left: 40px;

  @include lt-sm {
    width: 100%;
  }
}
.edition .accordion_wrap .accordion_list .accordion_title {
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  font-size: 14px;
}
.edition .accordion_wrap .accordion_list .accordion_title img {
  width: 16px;
  transform: rotate(180deg);
}

.edition .accordion_wrap .mat-expanded .accordion_title img {
  transform: rotate(0);
}
.edition .accordion_wrap .accordion_list .accordion_panel {
  padding-top: 12px;
}
.edition .accordion_wrap .accordion_list .accordion_panel dl dt {
  width: 30%;
}
.edition .accordion_wrap .accordion_list .accordion_panel dl dd {
  text-align: right;
  flex: 1;
}
.edition .item_list > li {
  width: calc(25% - 7.5px);
  margin-left: 10px;
}
.edition .item_list > li:nth-child(4n + 1) {
  margin-left: 0;
}
.edition .sort_wrap {
  display: flex;
  margin: 70px 0 60px;
  padding: 7px 18px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d8d8d8;
  border-radius: 10px;

  @include lt-lg {
    flex-direction: column;
    align-items: stretch;
  }
}
.edition .sort_wrap .result {
  font-size: 12px;
  color: #707070;
  white-space: nowrap;
}

/* 에디션 재판매 */
.edition.edition_resale .info_panel {
  border: 0;
}
.edition.edition_resale .info_panel dl {
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
}
.edition.edition_resale .info_panel dl dt {
  padding: 0 0 5px;
  font-weight: bold;
}
.edition.edition_resale .info_panel dl dd {
  font-weight: 400;
}
.edition.edition_resale .info_wrap.show_input .buttons {
  display: none;
}

/* 주문완료 */
.order_complete .inner {
  padding: 100px 15%;
}
.order_complete .ico_check {
  width: 55px;
}
.order_complete h3 {
  margin: 28px 0 0;
  font-size: 27px;
  letter-spacing: -2px;
  font-weight: 300;
}
.order_complete p {
  margin: 10px 0 0;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: -0.5px;
}
.order_complete .btn {
  width: 150px;
  margin-top: 60px;
  line-height: 40px;
  height: 40px;
  background-color: #2e2e2e;
  font-size: 14px;
  font-weight: 400;
}

/* 마이페이지 - 작품 상세 */
.work_detail#content {
  padding-bottom: 0;
}
.work_detail .product_name {
  padding: 50px 0;
}
.work_detail .product_name h5 {
  margin: 0 0 20px;
  font-size: 36px;
}
.work_detail .product_name p {
  display: inline-block;
  padding: 8px 15px;
  font-size: 11px;
  color: #5c5c5c;
  background-color: #f4f3f3;
  border-radius: 17px;
  font-weight: 400;
}
.work_detail .panel {
  padding: 30px 0 0;
}
.work_detail .panel01 dl {
  display: flex;
  align-items: center;
  padding: 8px 0;
}
.work_detail .panel01 dl dt {
  width: 40%;
  font-size: 11px;
}
.work_detail .panel01 dl dd {
  font-size: 11px;
}
.work_detail .panel02 .text {
  padding-right: 10%;
  font-size: 11px;
  line-height: 1.5;
}
.work_detail .panel02 .artist {
  padding: 50px 0;
}
.work_detail .panel02 .artist .author {
  margin: 30px 0 0;
}
.work_detail .right_area {
  position: relative;
  border-left: 2px solid #949191;
}
.work_detail .right_area:before {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 12px;
  height: 2px;
  content: '';
  background-color: #949191;
  transform: translateX(-50%);
}
.work_detail .right_area .product_name,
.work_detail .right_area .author {
  display: none;
}
.work_detail .right_area .info .price {
  padding: 20px 45px;
  border-bottom: 1px solid #ddd;
}
.work_detail .right_area .info .price p {
  font-size: 10px;
}
.work_detail .right_area .info .price strong {
  font-size: 27px;
  letter-spacing: -1px;
}
.work_detail .right_area .btn_wrap {
  text-align: right;
  margin: 45px 0;
}
.work_detail .right_area .btn_wrap .btn1 {
  display: inline-block;
  width: 183px;
  height: 47px;
  line-height: 47px;
  border-radius: 26px;
  font-size: 19px;
  font-weight: 500;
}
.work_detail .right_area .price_wrap {
  margin-bottom: 60px;
  padding: 0 45px;
}
.work_detail .right_area .price_input_wrap {
  display: none;
  padding: 20px 0;
}
.work_detail .right_area .price_input_wrap .row {
  margin-top: 30px;
}
.work_detail .right_area .price_input_wrap .row:nth-of-type(1) {
  margin-top: 0;
}
.work_detail .right_area .price_input_wrap .btn_wrap {
  margin-top: 22px;
}
.work_detail .right_area .price_input_wrap .btn_wrap .cancel {
  display: inline-block;
  width: 100px;
  height: 47px;
  line-height: 47px;
  border-radius: 26px;
  font-size: 19px;
  font-weight: 400;
  text-align: center;
}
.work_detail .right_area .price_input_wrap .p1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.work_detail .right_area .price_input_wrap .p1 span {
  font-size: 12px;
}
.work_detail .right_area .price_input_wrap .input_area {
  position: relative;
  margin-top: 20px;
  padding-top: 2px;
  border-radius: 26px;
  border: 2px solid #000;
  width: 100%;
}
.work_detail .right_area .price_input_wrap .input_area input {
  width: 100%;
  padding: 0 56px 0 20px;
  border: 0;
  font-size: 20px;
  line-height: 48px;
  background-color: transparent;
  font-weight: bold;
  letter-spacing: -0.5px;
  text-align: right;
  outline: none;
}
.work_detail .right_area .price_input_wrap .input_area span {
  position: absolute;
  right: 28px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  letter-spacing: -0.2px;
}
.work_detail .right_area .price_input_wrap .info_text01 {
  margin: 45px 0 0;
  font-size: 12px;
  color: #848484;
  letter-spacing: -0.5px;
}
.work_detail .right_area .price_input_wrap .info_text02 {
  margin: 34px 0 0;
  font-size: 12px;
  color: #2e2e2e;
  letter-spacing: -0.5px;
}
.work_detail .right_area .price_wrap.show .default {
  display: none;
}
.work_detail .right_area .price_wrap.show .price_input_wrap {
  display: block;
}
.work_detail .inner {
  position: relative;
}
.work_detail .fixed_btns {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(100%);
  padding-left: 14px;
}
.work_detail .fixed_btns a {
  display: flex;
  width: 49px;
  height: 49px;
  margin-bottom: 6px;
  border-radius: 13px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.32);
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.work_detail .fixed_btns a img {
  width: 24.5px;
}
.work_detail .fixed_btns a:nth-child(2) img {
  width: 16px;
}

/* 오픈마켓 */
.open_market .top_area {
  display: flex;
  padding: 0 0 65px;
  justify-content: space-between;
  align-items: center;
}
.open_market .top_area .page_title {
  margin: 0;
}
.open_market .sort_wrap {
  display: flex;
  padding: 0 0 30px;
  justify-content: space-between;
  align-items: center;
}
.open_market .sort_wrap .result {
  color: #707070;
  font-size: 12px;
}
.open_market .options {
  font-family: 'Noto Sans KR', sans-serif;
  margin-bottom: 10px;
}
.open_market .options h5 {
  padding: 0 0 20px;
  font-size: 18px;
}
.open_market .options .cate_list .category-box {
  margin-top: 10px;
  padding: 10px 12px;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
}
.open_market .options .cate_list .category-box.active {
  border-color: #000;
}
.open_market .options .cate_list > li:first-child {
  margin-top: 0;
}
.open_market .options .cate_list .option_depth {
  margin: 10px 0 0;
  padding: 10px 0 0;
  border-top: 1px solid #d8d8d8;
}
.open_market .options .cate_list .option_depth > li {
  margin: 0 0 7px;
}
.open_market .options .cate_list .option_depth > li label {
  font-size: 14px;
}
.open_market .item_list {
  padding-left: 20px;
}
.layer_search {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
}
.layer_search .search_header {
  display: flex;
  align-items: center;
}
.layer_search .search_header .btn_back img {
  width: 20px;
}
.layer_search .search_header .search_wrap02 {
  width: calc(100% - 20px);
  padding-left: 20px;
}
.layer_search .search_header .search_wrap02 input {
  width: 100%;
}
.layer_search .recommend {
  padding: 50px 0 0;
}
.layer_search .recommend h5 {
  margin: 0 0 20px;
  font-size: 10px;
  font-weight: 600;
}
.layer_search .recommend li {
  margin: 10px 0 0;
  font-size: 14px;
  font-weight: 600;
}
.layer_search .recommend li:first-child {
  margin: 0;
}
.search_market {
  display: none;
}
.mobile_layout.scrolled .qr_m {
  display: none;
}
.mobile_layout.scrolled .search_market {
  display: block;
  width: 30px;
  padding: 5px;
}

/* 마이페이지 */
.mypage#content {
  padding: 0;
}
.mypage .inner {
  position: relative;
  max-width: 1300px;
}
.mypage .bg_c {
  background-color: #f5f5f5;
}
.mypage .sub_layout .bg_area {
  height: 300px;
  background: url(/assets/images/temp_img.jpg) no-repeat 50% 0;
  background-size: cover;
}
.mypage .sub_layout .bg_area .inner {
  position: relative;
  height: 100%;
}
.mypage .setting {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5) url(/assets/icons/ic_gear@2x.png) no-repeat 50% 50% / 20px;

  &.delete {
    background: rgba(255, 255, 255, 0.5) url(/assets/icons/10@2x.png) no-repeat 50% 50% / 20px;
  }
}
.mypage .sub_layout .bg_area .setting {
  position: absolute;
  right: 0;
  bottom: 10px;
}
.mypage .sub_layout .bg_area .setting.delete {
  right: 40px;
}
.mypage .tab_ty01_wrap {
  border-bottom: 1px solid #eee;
}
.mypage .tab_ty01_wrap .tab_ty01 {
  justify-content: center;
}
.mypage .tab_ty01_wrap .tab_ty01 a {
  display: block;
  padding: 9px 0;
  font-size: 12px;
}

.mypage .item-box {
  padding: 100px 0 150px;

  @include xs() {
    padding: 30px 0 100px;
  }
}

.mypage .item_list,
.mypage .pagination {
  width: 70%;
  margin: 0 0 0 auto;
}
.mypage .item_list > li {
  width: calc(25% - 7.5px);
  margin-left: 10px;
}
.mypage .item_list > li:nth-child(4n + 1) {
  margin-left: 0;
}
.mypage .profile {
  position: absolute;
  top: -110px;
  z-index: 10;
  width: 300px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.04);
}
.mypage .profile .user_info {
  text-align: center;
  padding: 30px;
}
.mypage .profile .user_info strong {
  display: none;
  font-size: 12px;
  letter-spacing: -0.8px;
}
.mypage .profile .point dl {
  display: flex;
  padding: 15px 30px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}
.mypage .profile .p_thumb {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto 16px;
}
.mypage .profile .p_thumb .overflow {
  overflow: hidden;
  width: 100%;
  height: 150px;
}
.mypage .profile .p_thumb .setting {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.mypage .profile .p_thumb .setting.delete {
  right: 45px;
}
.mypage .profile h5 {
  font-size: 16px;
  letter-spacing: -1px;
}
.mypage .profile h5 a {
  display: block;
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-left: auto;
}
.mypage .profile .email {
  font-size: 12px;
}
.mypage .my_list {
  padding: 0 30px 15px;
}
.mypage .my_list > li > a {
  display: block;
  position: relative;
  padding: 15px 0;
  font-size: 16px;
  border-bottom: 1px solid #d8d8d8;
}
.mypage .my_list > li > a:after {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  width: 16px;
  height: 16px;
  content: '';
  background: url(/assets/icons/ic_tri_003@2x.png) no-repeat;
  transform: translateY(-50%) rotate(90deg);
  background-size: contain;
}
.mypage .my_list > li:last-child > a {
  border-bottom: 0;
}
.mypage .my_list > li > a.active {
  font-weight: bold;
}
.mypage .tab_wrap {
  display: none;
}

@media screen and (min-width: 1024px) {
  /*desktop*/
  .terms .shadow_box .text_content::-webkit-scrollbar {
    width: 10px;
  }
  .terms .shadow_box .text_content::-webkit-scrollbar-thumb {
    height: 17%;
    background-color: #ccc;
    border-radius: 10px;
  }
  .terms .shadow_box .text_content::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
}
@media screen and (max-width: 1300px) {
  /*Tablet*/
  .section01 .inner {
    padding: 0;
  }
}
@media screen and (max-width: 1024px) {
  /*Tablet*/
  .main .section01 .swiper-button-prev {
    left: 5%;
  }

  /* 상품상세 */
  .item_detail .inner {
    display: block;
  }
  .item_detail .inner .item_thumb {
    margin: 0 auto 40px;
  }
  .item_detail .inner .info_wrap {
    padding-right: 0;
  }

  /* 마이페이지 - 작품 상세 */
  .work_detail .fixed_btns {
    right: 50px;
    top: 20px;
    transform: translateX(0);
    padding: 0;
  }
  .work_detail .fixed_btns a {
    width: 34px;
    height: 35px;
    border-radius: 8px;
  }
  .work_detail .fixed_btns a img {
    width: 14px;
  }
  .work_detail .fixed_btns a:nth-child(2) img {
    width: 12px;
  }

  /* 마이페이지 */
  .mypage .inner {
    padding: 0;
  }
  .mypage .sub_layout .bg_area {
    height: 200px;
  }
  .mypage .profile {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: -70px;
    width: 180px;
    top: -90px;
  }
  .mypage .profile .point dl {
    border-bottom: 0;
    padding: 15px;
    font-size: 12px;
  }
  .mypage .profile .p_thumb {
    width: 100px;
    height: 100px;
  }
  .mypage .profile .p_thumb .overflow {
    height: 100px;
  }
  .mypage .profile .user_info {
    padding: 20px 30px;
  }
  .mypage .item_list,
  .mypage .pagination {
    width: 100%;
    padding: 20px 30px;
    background: #f6f6f6;
    justify-content: center;
  }
  .mypage .sub_layout .bg_area .setting {
    right: 15px;
    bottom: 10px;
  }
  .mypage .sub_layout .bg_area .setting.delete {
    right: 15px;
    bottom: 45px;
  }
  .mypage .my_list {
    display: none;
  }
  .mypage .tab_ty01 {
    justify-content: center;
  }
  .mypage .tab_ty01 > li > a {
    display: block;
    padding: 12px 4px;
    font-size: 12px;
    font-family: 'Gothic A1', sans-serif;
    font-weight: bold;
  }
  .mypage .tab_ty01 > * {
    position: relative;
    margin: 0;
    padding: 0 12px;

    &:first-child {
      padding-left: 25px;
    }

    &:last-child {
      padding-right: 25px;
    }
  }
  .mypage .tab_ty01 > *.active:before {
    display: none;
  }
  .mypage .tab_ty01 .active a {
    position: relative;
  }
  .mypage .tab_ty01 .active a:before {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: #222;
    content: '';
    transform: translateX(-50%);
  }
  .mypage .bg_c {
    background: transparent;
  }
  .mypage .item_list > li {
    width: 100%;
    max-width: 400px;
  }
  .mypage .profile .p_thumb .setting {
    right: -10px;
    bottom: -10px;
  }

  .mypage .profile .p_thumb .setting.delete {
    right: 25px;
  }

  .mypage .tab_wrap {
    display: block;
  }

  .mypage.scrolled .profile {
    width: 100%;
    border-radius: 0;
    background: #fff;
    left: 0;
    transform: translateX(0);
  }
  .mypage.scrolled .profile .point {
    display: none;
  }
  .mypage.scrolled .profile .user_info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .mypage.scrolled .profile .p_thumb {
    margin: 0;
  }
  .mypage.scrolled .profile .p_info {
    padding-left: 14px;
    text-align: left;
  }
  .mypage.scrolled .profile .p_info strong {
    font-size: 12px;
  }
  .mypage.scrolled .profile .p_thumb {
    width: 80px;
    height: 80px;
  }
  .mypage.scrolled .profile .p_thumb .overflow {
    width: 80px;
    height: 80px;
  }
  .mypage.scrolled .profile .email {
    margin: 3px 0 0;
  }
  .mypage.scrolled .profile .user_info strong {
    display: block;
    padding: 3px 0 0;
  }
}
@media screen and (max-width: 768px) {
  /*Tablet*/

  /* main - 키비쥬얼 */
  .main .section01 .inner {
    padding: 0;
  }
  .main .section01 .vertical_txt {
    font-size: 12px;
    line-height: 1.2;
  }
  .main .section01 .vertical_txt:after {
    display: none;
  }
  .main .section01 .txt_area h5 {
    padding: 0 0 0 8%;
  }
  .main .section01 .txt_area h5 p:nth-child(1) {
    font-size: 28px;
  }
  .main .section01 .txt_area h5 p:nth-child(2) {
    font-size: 55px;
    letter-spacing: -1px;
    margin: 0;
  }
  .main .section01 .swiper-button-prev {
    left: 5%;
    bottom: 18px;
    width: 45px;
    height: 45px;
    background-size: cover;
  }
  .main .section01 .txt_area .small_txt {
    right: 25%;
    height: 130px;
  }

  /* main - 핫한작품 */
  .main .section02 {
    padding: 50px 0 70px;
  }
  .main .section02 h3 {
    font-size: 27px;
    padding: 0 0 30px;
  }

  /* 상품선택 */
  .asset_product .choice {
    margin: 20px 0 30px;
  }
  .asset_product .layout_5_5 {
    flex-direction: column;
  }
  .asset_product .layout_5_5 > * {
    width: 100%;
    padding: 0;
  }
  .asset_product .item_detail {
    margin: 30px 0 0;
  }
  .asset_product .item_detail .item_title p {
    margin: 0;
    font-size: 22px;
    line-height: 1.3;
  }
  .asset_product .item_detail .item_input {
    margin: 10px 0 0;
  }
  .asset_product .item_img {
    margin-top: 10px;
  }
  .asset_product .btns {
    width: 100%;
    padding: 0;
  }
  .asset_product .btns_wrap {
    padding: 25px 0 0;
  }
  .asset_product .item .text {
    font-size: 20px;
  }
  .asset_product .all .text {
    font-size: 15px;
    letter-spacing: -1px;
    font-weight: 400;
  }
  .asset_product .wrapper {
    width: 100%;
    padding: 0;
  }
  .layer_popup.gallery .popup .popup_header {
    margin: 0 4% 30px;
  }
  .layer_popup.gallery .popup_content img {
    width: 100%;
  }

  /* 공통 */
  .page_title {
    margin: 0 0 20px;
    font-size: 20px;
  }
  .page_title.type02 {
    font-size: 20px;
  }
  .shadow_box {
    border-radius: 15px;
  }

  /* 충전페이지 */
  .charge input[type='text'] {
    padding: 0 10px;
    height: 30px;
    line-height: 28px;
  }
  .charge .user_info {
    flex-direction: column;
  }
  .charge .user_info .user_name {
    width: 200px;
    margin: 0 auto 15px;
  }
  .charge .user_info .terms {
    margin: 10px 0 0;
  }
  .charge .charge_info .quantity {
    width: 300px;
    flex-direction: column;
  }
  .charge .charge_info .quantity .cell:first-child {
    width: 100%;
    margin: 0 0 10px;
  }
  .charge .charge_info .quantity .cell:nth-child(2) {
    width: 100%;
    padding-left: 0;
  }
  .charge .charge_info .quantity .cell p {
    padding: 3px 0 0;
    font-size: 11px;
  }
  .charge .bottom {
    padding-top: 30px;
    flex-direction: column;
    align-items: center;
  }
  .charge .bottom .total {
    margin: 15px 0 25px;
    font-size: 18px;
  }
  .charge .bottom .info_txt {
    margin-bottom: 5px;
    font-weight: 400;
  }
  .charge .btn_wrap {
    width: 100%;
    text-align: center;
  }
  .charge .btn_wrap .btn_ty01 {
    width: 100%;
    max-width: 350px;
    margin-top: 0;
  }
  .charge .btn_wrap .terms {
    margin: 0 0 10px;
  }
  .charge .btn_wrap .terms a {
    font-size: 10px;
  }
  .charge .radio_wrap {
    text-align: center;
  }

  /* 이용약관 */
  .terms .shadow_box {
    padding: 30px 3%;
  }
  .terms .shadow_box .text_content {
    height: 50vh;
  }
  .terms .shadow_box .text_content::-webkit-scrollbar {
    width: 5px;
  }
  .terms .shadow_box .text_content strong {
    font-size: 12px;
    letter-spacing: -0.8px;
  }
  .terms .shadow_box .text_content p {
    font-size: 11px;
    letter-spacing: -0.8px;
    line-height: 1.5;
  }
  .terms .shadow_box .text_content .mb_45 {
    margin-bottom: 20px;
  }

  /* 로그인 */
  .login {
    margin: 30px auto 20px;
  }

  /* 장바구니 */
  .cart .cart_table thead th:nth-child(1) {
    width: 8%;
  }
  .cart .cart_table thead th:nth-child(2) {
    width: 58%;
  }
  .cart .cart_table thead th:nth-child(3) {
    width: 30%;
  }
  .cart .cart_table tbody .thumb {
    width: 50px;
    min-width: 50px;
  }
  .cart .cart_table tbody .info {
    flex-direction: column;
    align-items: flex-start;
  }
  .cart .cart_table tbody .p_wrap {
    flex-direction: column-reverse;
    align-items: flex-start;
    position: relative;
  }
  .cart .cart_table tbody .p_wrap .p1 {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4;
  }
  .cart .cart_table tbody .p_wrap .p2 {
    font-weight: 500;
    font-size: 24px;
    position: absolute;
    left: 60px;
    top: 0;
    transform: translateY(-100%);
  }
  .cart .cart_table tbody .p_wrap .p3 {
    margin-top: 20px;
  }
  .cart .cart_table tbody td.price {
    font-size: 12px;
  }
  .cart .cart_table tbody .del {
    vertical-align: bottom;
  }
  .cart .cart_table tbody .del button {
    font-weight: 300;
  }
  .cart .table_btns .check_ty01 {
    width: 8%;
  }
  .cart .table_btns a {
    font-weight: 400;
  }
  .cart .total {
    padding: 50px 0 30px;
  }
  .cart .btns {
    font-size: 0;
  }
  .cart .btns a {
    width: calc(50% - 5px);
    margin-left: 10px;
  }
  .cart .btns a:first-child {
    margin-left: 0;
  }

  /* 창작작품 */
  .work .top_author {
    margin: 15px 0 35px;
    flex-direction: column;
  }
  .work .top_author .profile_img {
    width: 54px;
    height: 54px;
    margin: 0 0 13px;
  }
  .work .top_author strong {
    margin: 0 0 12px;
  }
  .work .tab_ty01 {
    justify-content: center;
  }
  .work .charge_info .shadow_box {
    width: 100%;
    margin: 0;
  }

  /* 공지사항 */
  .notice .layout_5_5 {
    flex-direction: column;
  }
  .notice .layout_5_5 > * {
    width: 100%;
  }
  .notice .search_wrap {
    margin: 0 0 25px;
  }
  .notice .search_wrap form {
    width: 100%;
  }
  .notice .search_wrap input {
    padding: 14px 40px 12px 12px;
  }
  .notice .search_wrap button[type='submit'] img {
    width: 20px;
  }
  .notice .list_wrap {
    padding: 0 0 25px;
  }
  .notice .text {
    border-top: 1px solid #c9c9c9;
    border-left: 0;
    padding: 30px 0 0;
    font-size: 13px;
    line-height: 1.6;
  }
  .notice .text:before {
    left: auto;
    bottom: auto;
    top: 0;
    right: 0;
    transform: translateY(-50%);
  }
  .notice .pagination {
    padding: 20px 0 0;
    text-align: center;
  }
  .table_ty01 thead th:nth-child(1) {
    width: 38px;
  }

  /* 상품상세 */
  .prd_detail .inner {
    padding: 0;
  }

  /* (공통)상세 */
  .item_detail .inner .item_thumb {
    width: 100%;
    max-width: 450px;
    margin-bottom: 24px;
  }
  .item_detail .inner .info_wrap {
    position: relative;
    margin: 0 5%;
  }
  .item_detail .inner .info_wrap .title h5 {
    line-height: 1.5;
  }
  .item_detail .inner .info_wrap .description {
    display: flex;
    flex-direction: column;
  }
  .item_detail .inner .info_wrap .description .price_wrap {
    margin: 15px 0 0;
    padding: 0 0 10px;
  }
  .item_detail .inner .info_wrap .description .text {
    margin: 10px 0 20px;
  }
  .item_detail .inner .info_wrap .etc {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
  }
  .item_detail .inner .accordion_wrap {
    margin: 20px 0 0;
  }
  .item_detail .inner .info_wrap .btns a {
    font-size: 12px;
  }

  /* 에디션 */
  .edition .inner {
    padding: 0 5%;
  }
  .edition .inner .info_wrap {
    margin: 0;
    padding: 0;
  }
  .edition .sort_wrap {
    margin: 30px 0;
    padding: 5px 10px;
  }
  .edition .accordion_wrap .accordion_list > li:nth-child(2n) {
    width: 100%;
    padding-left: 0;
  }
  .edition .description .etc_list {
    margin: 15px 0;
    flex-wrap: wrap;
  }
  .edition .description .etc_list dl {
    width: 100%;
    margin: 0 0 5px;
  }
  .edition .description .etc_list span {
    margin: 0 20px 0 0;
  }

  /* 마이페이지 - 작품 상세 */
  .work_detail#content {
    border-top: 0;
    margin: 0;
  }
  .work_detail .inner {
    padding: 0;
  }
  .work_detail .layout_5_5 {
    display: flex;
    flex-direction: column-reverse;
  }
  .work_detail .layout_5_5 > * {
    width: 100%;
  }
  .work_detail .left_area,
  .work_detail .right_area .price_wrap,
  .work_detail .right_area .info {
    padding: 0 6%;
  }
  .work_detail .left_area {
    padding-bottom: 65px;
  }
  .work_detail .left_area .product_name,
  .work_detail .left_area .top_author {
    display: none;
  }
  .work_detail .right_area {
    border-left: 0;
  }
  .work_detail .right_area:before {
    display: none;
  }
  .work_detail .right_area .product_name {
    display: block;
  }
  .work_detail .right_area .author {
    display: flex;
  }
  .work_detail .right_area .info .price {
    border-bottom: 0;
    padding: 10px 0 0;
  }
  .work_detail .product_name h5 {
    font-size: 28px;
    text-align: left;
  }
  .work_detail .right_area .info {
    padding-top: 15px;
  }
  .work_detail .right_area .product_name {
    padding: 10px 0 15px;
  }
  .work_detail .right_area .product_name h5 {
    margin: 0;
    font-weight: 500;
    letter-spacing: -1px;
  }
  .work_detail .right_area .btn_wrap {
    margin: 0;
  }
  .work_detail .right_area .price_input_wrap .btn_wrap .cancel,
  .work_detail .right_area .btn_wrap .btn1 {
    width: 98px;
    height: 35px;
    font-size: 13px;
    line-height: 35px;
  }
  .work_detail .right_area .price_wrap {
    margin-bottom: 45px;
  }
  .work_detail .panel02 .artist {
    padding: 30px 0 0;
  }
  .work_detail .right_area .price_input_wrap .input_area {
    margin-top: 10px;
    border-width: 1px;
    padding-top: 1px;
  }
  .work_detail .right_area .price_input_wrap .input_area input {
    line-height: 35px;
    font-size: 16px;
  }
  .work_detail .right_area .price_input_wrap .row {
    margin-top: 20px;
  }
  .work_detail .right_area .price_input_wrap .info_text01 {
    margin: 25px 0 0;
  }
  .work_detail .right_area .price_input_wrap .info_text02 {
    margin: 20px 0 0;
  }
  .work_detail .fixed_btns {
    right: 10px;
    top: 40px;
  }

  /* 오픈 마켓 */
  .open_market .inner {
    padding: 0;
  }
  .open_market .sort_wrap {
    display: none;
  }
  .open_market .top_area {
    flex-direction: column;
    padding: 0 0 30px;
  }
  .open_market .top_area .page_title {
    margin: 0 0 20px;
  }
  .open_market .top_area .search_wrap02 input[type='text'] {
    width: 240px;
    padding: 10px 30px 10px 50px;
    border-radius: 30px;
    background-color: #f4f4f4;
    border: 0;
    background-position-x: 20px;
  }
  .open_market .top_area .search_wrap02 input[type='text']::placeholder {
    color: #333;
  }
  .open_market .layout_2_8 {
    flex-direction: column;
  }
  .open_market .layout_2_8 > * {
    width: 100%;
  }
  .open_market .list {
    padding: 15px 20px;
    background-color: #eee;
  }
  .open_market .tab_menu {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }
  .open_market .tab_menu > li {
    display: table-cell;
    width: 33.3%;
    padding: 10px 0;
    text-align: center;
    border: 1px solid #d8d8d8;
    font-size: 12px;
  }
  .open_market .tab_menu > li.active {
    border-bottom-color: #fff;
  }
  .open_market .tab_menu > li .filter-status:after {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    background: url(/assets/icons/icon_up@2x.png) no-repeat;
    background-size: 16px;
    content: '';
    transform: rotate(180deg);
    vertical-align: middle;
  }
  .open_market .tab_menu > li.active .filter-status:after {
    transform: rotate(0deg);
  }
  .open_market .tab_panel .panel {
    padding: 20px 30px;
    border-bottom: 1px solid #d8d8d8;
  }
  .open_market .tab_panel .panel label {
    font-size: 12px;
    font-weight: 400;
  }
  .open_market .tab_panel .panel .check_ty02 {
    margin: 10px 0 0;
  }
  .open_market .tab_panel .panel .check_ty02:first-child {
    margin: 0;
  }
  .open_market .item_list {
    padding-left: 0;
  }
  .open_market .pagination02 {
    margin: 10px 0 20px;
  }
  .open_market#content {
    padding-bottom: 0;
  }

  /* 에디션 */
  .edition .flex {
    display: block;
  }
  .edition .item_list > li {
    width: calc(50% - 5px);
    margin-left: 10px;
  }
  .edition .item_list > li:nth-child(2n + 1) {
    margin-left: 0;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 620px) {
  /*Mobile*/

  /* main - 키비쥬얼 */
  .main .section01 {
    width: 90%;
    margin: 0 auto;
  }
  .main .section01 .swiper-slide a {
    flex-direction: column-reverse;
  }
  .main .section01 .txt_area {
    width: 100%;
  }
  .main .section01 .txt_area h5 {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 0 28px;
    font-size: 22px;
  }
  .main .section01 .txt_area h5 p:nth-child(2) {
    padding-left: 25px;
    font-size: 38px;
  }
  .main .section01 .txt_area .small_txt {
    display: none;
  }
  .main .section01 .txt_area .vertical_txt {
    position: static;
    margin: 0px 0 20px auto;
    height: 260px;
  }
  .main .section01 .img_area {
    width: 100%;
    height: auto;
    padding: 0 0 85%;
    text-align: center;
  }
  .main .section01 .img_area img {
    width: 100%;
    height: auto;
  }
  .main .section01 .swiper-button-prev {
    bottom: auto;
    top: 75%;
    left: 0;
  }

  /* main - 핫한작품 */
  .main .section02 {
    padding-top: 30px;
  }
  .main .section02 h3 {
    padding: 0 0 15px;
    font-size: 18px;
  }

  /* 에디션 */
  .edition .item_list > li {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}
