@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
/*font-family: 'Noto Sans KR', sans-serif;*/
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap');  */
/* font-family: 'Montserrat', sans-serif; */
//@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;800&display=swap');
///* font-family: 'Gothic A1', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai+Looped:wght@200;300;400;500;600;700&display=swap');
/* font-family: 'IBM Plex Sans Thai Looped', sans-serif; */
@font-face {
  font-family: 'Gothica1';
  font-weight: 600;
  src: url(/assets/fonts/Gothic_A1/GothicA1-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Gothica1';
  font-weight: 900;
  src: url(/assets/fonts/Gothic_A1/GothicA1-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Gothica1';
  font-weight: 300;
  src: url(/assets/fonts/Gothic_A1/GothicA1-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Gothica1';
  font-weight: 400;
  src: local(GothicA1-Medium), url(/assets/fonts/Gothic_A1/GothicA1-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Gothica1';
  font-weight: 500;
  src: url(/assets/fonts/Gothic_A1/GothicA1-SemiBold.ttf) format('truetype');
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  line-height: 1.4;
}

input {
  border-radius: 0;
}

input[type='text'],
input[type='email'],
select {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  box-sizing: border-box;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
  // color: #222;
  cursor: pointer;
}
body,
input,
textarea,
select,
button {
  // font-family: 'Gothica1', sans-serif;
  font-family: 'Montserrat_Regular';
  box-sizing: border-box;
}
button {
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
}
img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
input[type='checkbox'],
input[type='radio'] {
  display: block;
}
body {
  overflow-x: hidden;
}

.mat-expansion-panel-header,
.mat-expansion-panel-body {
  padding: 0 !important;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
