@import 'core';

.common_title_area{
  position: relative;
  width: 100%;
  text-align: center;

  .main_title{
    font-family: 'Couture-Bld';
    font-size: 30px;
    line-height: 55px;
    padding: 80px;

    @include lt-lg(){
      display: none;
    }
    @include sm {
      display: none;
    }
  }

  .sub_title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Couture-Bld';
    font-size: 16px;
    line-height: 20px;
    color: #888;
    margin: 0 auto;
    margin-bottom: 28px;
    width: fit-content;
    border: 1px solid #888;
    border-radius: 10px;

    @include sm {
      width: calc(100% - 140px);
      font-size: 15px;
      justify-content: space-evenly;
      margin-bottom: 20px;
    }

    div{
      cursor: pointer;
      padding: 7px 35px;

      @include sm {
        padding: 7px;
        display: flex;
      }
    }
    div.active{
      color: #37aae1;
    }
  }

  .sub_title2{
    div{
      padding: 7px 130px;

      @include sm {
        padding: 7px;
        display: flex;
      }
    }
  }
  
  .main_menu{
  
    .title_menu_area{
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      height: 100%;

      .title_menu_layout, .title_menu_layout2{
        width: 1300px;
        height: 53px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        @include lt-lg(){
          width: 100%;
        }
        @include sm {
          width: 100%;
          height: 45px;
        }

        .title_menu{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          font-family: 'Montserrat_Bold';
          color: #b7b7b7;
          font-size: 20px;
          line-height: 38px;
          border-right: 1px solid #dddddd;

          @include sm {
            font-size: 16px;
            line-height: 16px;
            font-family: 'Montserrat_SemiBold';

            :first-child{
              border-left: 0px !important;
              border-right: 0px !important;
            }
            :last-child{
              border-left: 0px !important;
              border-right: 0px !important;
            }
          }
        }
        .title_menu:first-child{
          border-left: 1px solid #dddddd;
        }
        .title_menu.active{
          color: #fff;
          background-color: #000;
          border-bottom: 1px solid #000;
        }
        .title_menu a:hover{
          cursor: pointer;
        }
        
      }
      .title_menu_layout2{
        display: none;
        
        @include sm{
          display: block;
          height: 100%;
          
          .layout_line, .layout_line2{
            display: flex;
            height: 45px;
            border-bottom: 1px solid #ececec;

            .title_menu{
              // width: 45%;
              width: 50%;
              font-size: 13px !important;
              line-height: 13px !important;
              font-family: 'Montserrat_SemiBold' !important;
            }
            .arrow_menu{
              width: 10%;
              display: flex;
              align-items: center;
              justify-content: center;

              .xi-angle-down, .xi-angle-up{
                  font-size: 15px;
              }
            }
          }
          .layout_line2{
            display: flex;
            border-bottom: 0px;
          }
          
        }

      }
    }
      
    .sub_menu_area{
      height: 50px;
      font-size: 14px;
      border-bottom: 1px solid #ececec;

      @include sm {
        height: 40px;
        padding: 0 20px;
        margin: 0 auto;
        font-size: 14px;
        overflow: hidden;
      }

      .sub_menu_layout{
        width: 1300px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        @include lt-lg(){
          width: 100%;
        }
        @include sm {
          width: 100%;
        }
        .sub_menu, .sub_menu2{
          width: 100%;
          height: 100%;
          display: none;
          align-items: center;
          justify-content: space-evenly;
          font-family: 'Montserrat_SemiBold';
          color: #b7b7b7;
        }
        .sub_menu{
          @include sm {
            justify-content: flex-start;
          }
        }
        .sub_menu.active, .sub_menu2.active{
          display: flex;
          width: 100%;
        }
        .sub_menu.active li, .sub_menu2.active li{
          height: 100%;
          display: flex;
          align-items: center;

          @include sm {
            font-size: 14px;
          }
        }
        .sub_menu.active li{
          @include sm {
            margin-right: 60px;
          }
        }
        .sub_menu.active li.active, .sub_menu2.active li.active{
          border-bottom: 3px solid #000;
          color: #000;
          font-family: 'Montserrat_Bold';
        }
        .sub_menu a:hover, .sub_menu2 a:hover{
          cursor: pointer;
        }
      }
    }
  }
  
  .navigator{
    width: 1300px;
    margin: 0 auto;
    margin-top: 20px;
    padding-bottom: 20px;
    color: #8b8b8b;
    font-size: 12px;
    line-height: 26px;
    text-align: left;

    @include lt-lg(){
      padding:0 20px;
      padding-bottom: 20px;
      width: 100%;
    }
    @include sm{
      // display: none;
      width: 100%;
    }

    .current_page{
      color: #000;
    }
  }
  
}

  /* 공통 서브 */
.common_sub_area{
  width: 1300px;
  margin: 0 auto;
  padding-bottom: 15px;

  display: flex;
  justify-content: space-between;
  align-items: end;
  flex-direction: row-reverse;
    
  @include lt-lg{
    width: 100%;
  }
  @include sm {
    width: 100%;
  }

  .result_cnt{
    color: #949494;
    font-size: 14px;
    opacity: 0.8;
    padding-left: 5px;
  }
  .filter_area{
    display: flex;
    align-items: end;
    font-size: 12px;

    @include sm {
      width: 100%;
      justify-content: space-between;
    }

    .item_type{
      position: relative;
      width: 185px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid rgba(148, 148, 148, 0.3);
      border-radius: 7px;
      cursor: pointer;

      .item_filter{
        display: flex;
        align-items: center;
        letter-spacing: -0.5px;
        padding: 0 0 0 15px;
        z-index: 1;
      }
      .xi-angle-down{
        position: absolute;
        right: 10px;
      }
      .option{
        position: absolute;
        top: 55px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #d2d2d2;
        border-radius: 10px;
        overflow: hidden;
        display: none;
        z-index: 1;
      }
      .option div{
        padding: 12px 15px;
        cursor: pointer;
        height: 25px;
        display: flex;
        align-items: center;
      }
      .option div:hover{
        background-color: #37aae1;
        color:#fff;
      }
      .option div.act{
        font-family: 'Montserrat_SemiBold';
        color: #37aae1;
      }
      .option div.act:hover{
        color:#fff;
      }
    }

    .item_type.act .option{
      display: block;
    }

    .filter{
      font-family: 'Montserrat_Medium';
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: end;
      cursor: pointer;
      position: relative;

      .order-filter{
        font-size: 14px;
        margin-top: -3px;
      }
      .order{
        position: absolute;
        top: 44px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #d2d2d2;
        border-radius: 10px;
        overflow: hidden;
        display: none;
        z-index: 1;
      }
      .order div{
        padding: 12px 15px;
        cursor: pointer;
        height: 25px;
        display: flex;
        align-items: center;
      }
      .order div:hover{
        background-color: #37aae1;
        color:#fff;
      }
      .order div.act{
        font-family: 'Montserrat_SemiBold';
        color: #37aae1;
      }
      .order div.act:hover{
        color:#fff;
      }
    }

    .filter img{
      width: 20px;
      padding-left: 5px;
    }
    .filter.act .order{
      display: block;
    }
  }
}

/* 공통 검색 */
.common_search_area{
  height: 100px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-items: center;

  .search_bar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .xi-search{
      font-size: 25px;
      padding: 10px;
      margin-left: -36px;
      background-color: #000;
      color: #fff;
      border-radius: 50px;
    }
  }
  .search_bar input{
    width: 440px;
    height: 43px;
    border-radius: 50px;
    border: 1px solid rgba(148, 148, 148, 0.2);
    padding: 0 20px;
    font-size: 16px;
    line-height: 16px;
    color: #949494;
    font-family: 'Montserrat_Regular';
    background-color: #fbfbfb;

    @include sm{
      width: 100%;
    }
  }
  .search_bar input:focus{
    outline: none;
  }   
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ellipsis5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}


.upload_area{
  .upload-button-box{

    @include sm{
      display: block !important;
  
      .nik-button{
        padding:15px;
      }
      .upload-describe{
        width: 100%;
        display: block;
        margin-top: 20px;
      }
    }
  }
}

