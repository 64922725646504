$color-primary: #2dccd3;
$color-secondary: #fd595e;
$color-warning: #ffa726;
$color-error: #c62828;
$color-white: #ffffff;
$color-black: #353639;

$color-web-black: #000;
$color-web-primary: #37aae1;
$color-web-secondary: #ffd512;
$color-web-secondary-light: #fffbdf;
$color-web-primary-light: #afdef3;
