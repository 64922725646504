/* NanumSquare */
@font-face {
    font-family: 'NanumSquareR';
    font-weight: 400;
    src: url(/assets/fonts/NanumSquare/NanumSquareR.otf) format('otf'), url(/assets/fonts/NanumSquare/NanumSquareR.ttf) format('truetype');
}
@font-face {
    font-family: 'NanumSquareB';
    font-weight: 700;
    src: url(/assets/fonts/NanumSquare/NanumSquareB.otf) format('otf'), url(/assets/fonts/NanumSquare/NanumSquareB.ttf) format('truetype');
}
@font-face {
    font-family: 'NanumSquareEB';
    font-weight: 800;
    src: url(/assets/fonts/NanumSquare/NanumSquareEB.otf) format('otf'), url(/assets/fonts/NanumSquare/NanumSquareEB.ttf) format('truetype');
}
@font-face {
    font-family: 'NanumSquareL';
    font-weight: 300;
    src: url(/assets/fonts/NanumSquare/NanumSquareL.otf) format('otf'), url(/assets/fonts/NanumSquare/NanumSquareL.ttf) format('truetype');
}
@font-face {
    font-family: 'NanumSquareAcR';
    font-weight: 300;
    src: url(/assets/fonts/NanumSquare/NanumSquare_acR.otf) format('otf'), url(/assets/fonts/NanumSquare/NanumSquare_acR.ttf) format('truetype');
}
@font-face {
    font-family: 'NanumSquareAcB';
    font-weight: 700;
    src: url(/assets/fonts/NanumSquare/NanumSquare_acB.otf) format('otf'), url(/assets/fonts/NanumSquare/NanumSquare_acB.ttf) format('truetype');
}
@font-face {
    font-family: 'NanumSquareAcEB';
    font-weight: 800;
    src: url(/assets/fonts/NanumSquare/NanumSquare_acEB.otf) format('otf'), url(/assets/fonts/NanumSquare/NanumSquare_acEB.ttf) format('truetype');
}
@font-face {
    font-family: 'NanumSquareAcL';
    font-weight: 300;
    src: url(/assets/fonts/NanumSquare/NanumSquare_acL.ttf) format('otf'), url(/assets/fonts/NanumSquare/NanumSquare_acL.ttf) format('truetype');
}

/* Montserrat */
@font-face {
    font-family: 'Montserrat_Thin';
    font-weight: 100;
    src: url(/assets/fonts/Montserrat/Montserrat-Thin.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_Thin_Italic';
    font-weight: 100;
    src: url(/assets/fonts/Montserrat/Montserrat-ThinItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_ExtraLight';
    font-weight: 200;
    src: url(/assets/fonts/Montserrat/Montserrat-ExtraLight.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_ExtraLight_Italic';
    font-weight: 200;
    src: url(/assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_Light';
    font-weight: 300;
    src: url(/assets/fonts/Montserrat/Montserrat-Light.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_Light_Italic';
    font-weight: 300;
    src: url(/assets/fonts/Montserrat/Montserrat-LightItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_Regular';
    font-weight: 400;
    src: url(/assets/fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_Medium';
    font-weight: 500;
    src: url(/assets/fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_Medium_Italic';
    font-weight: 500;
    src: url(/assets/fonts/Montserrat/Montserrat-MediumItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_SemiBold';
    font-weight: 600;
    src: url(/assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_SemiBold_Italic';
    font-weight: 600;
    src: url(/assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_Bold';
    font-weight: 700;
    src: url(/assets/fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_Bold_Italic';
    font-weight: 700;
    src: url(/assets/fonts/Montserrat/Montserrat-BoldItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_ExtraBold';
    font-weight: 800;
    src: url(/assets/fonts/Montserrat/Montserrat-ExtraBold.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_ExtraBold_Italic';
    font-weight: 800;
    src: url(/assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_Black';
    font-weight: 900;
    src: url(/assets/fonts/Montserrat/Montserrat-Black.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat_Black_Italic';
    font-weight: 900;
    src: url(/assets/fonts/Montserrat/Montserrat-BlackItalic.ttf) format('truetype');
}

/* Couture */
@font-face {
    font-family: 'Couture-Bld';
    src: url(/assets/fonts/couture/couture-bld.otf) format('truetype');
}
@font-face {
    font-family: 'Couture-Bldit';
    src: url(/assets/fonts/couture/couture-bldit.otf) format('truetype');
}
