.new-form-content {
  background-color: #f5f5f5;
}

.new-form-box {
  max-width: 560px;
  width: 100%;
  background-color: #ffffff;
  padding: 44px 40px 60px;
  margin: 0 auto;

  @include xs() {
    padding: 44px 20px 60px;
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    color: #000;
  }
}

.back-icon {
  width: 24px;
  height: 24px;
  margin-left: -6px;
  margin-bottom: 30px;
}

.form-field {
  margin-bottom: 40px;

  &.include-button-field {
    display: flex;
    align-items: flex-end;

    nik-input-form-field {
      flex: auto;
    }

    button {
      flex: 0 0 86px;
      margin-left: 15px;
      padding: 17px 20px;
      background-color: #f5f5f5;
      font-size: 13px;
      font-weight: 600;
      line-height: normal;
      color: #000;
    }
  }
}

.confirm-button {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: #fff;

  padding: 19px 0 16px;
  border-radius: 5px;
  background-color: #000;

  &:disabled {
    color: #fff;
    background-color: #d9d9d9;
  }
}
