@import "core";

.my-page-child-section {
  @include lt-lg() {
    width: 100%;
    padding: 39px 0 100px;
    background: #f6f6f6;
    justify-content: center;
  }

  width: 70%;
  margin: 0 0 0 auto;
  padding: 80px 0;
}

.my-page-contents-box {
  @include margin-bottom-without-last-child(20px);
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;

  @include lt-lg() {
    border-radius: 0;
  }
}

.my-contents-top-box {
  padding: 20px;
  border-bottom: 1px solid #d8d8d8;

  .my-contents-back-link {
    display: inline-block;
    margin-bottom: 23px;
  }

  .back-icon {
    margin-top: 4px;
    margin-left: -6px;
    margin-bottom: 0;
  }

  .order-detail-info-box {
    .order-detail-box {
      display: flex;
      align-items: center;
      height: 26px;
      margin-bottom: 18px;

      .order-detail {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #000;
      }

      .order-detail-cancel-button {
        padding: 7px 20px;
        border: solid 1px #000;
        background-color: #fff;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: right;
        color: #000;
        margin-left: 20px;
      }
    }

    .order-detail-info-list {
      .order-detail-info-item {
        @include margin-bottom-without-last-child(16px);
        display: flex;
        align-items: center;

        .order-info-label {
          font-weight: bold;
          width: 95px;
        }

        .order-info-label,
        .order-info-text {
          font-size: 12px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000;
        }
      }
    }
  }
}

.my-contents-box {
  padding: 23px 20px;
}

.amount-information-top-box {
  padding: 23px 20px 17px;
  border-bottom: 1px solid #d8d8d8;

  h4 {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
}

.amount-information-contents-box {
  display: flex;
  justify-content: space-between;

  @include xs() {
    flex-direction: column;
    justify-content: flex-start;
  }

  .deposit-information-box {
    padding: 25px 20px 50px;
    background-color: #fff;

    .deposit-information-title {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      margin-bottom: 27px;
    }

    .deposit-information-list {
      .deposit-information-item {
        @include margin-bottom-without-last-child(16px);
        width: 250px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include xs() {
          width: 100%;
        }

        .label,
        .info {
          font-size: 12px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000;
        }
      }
    }
  }

  .deposit-amount-box {
    padding: 25px 20px 141px;
    background-color: #000;
    width: 302px;

    @include xs() {
      width: 100%;
    }

    .deposit-amount-item,
    .recharge-amount-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .deposit-amount-item {
      margin-bottom: 16px;

      .label,
      .amount {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $color-web-primary;
      }
    }

    .recharge-amount-item {
      .label,
      .amount {
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
      }
    }
  }
}
